import { Button } from "../ui/button";
import { Card, CardContent, CardHeader, CardTitle } from "../ui/card";
import {
  Carousel,
  CarouselContent,
  CarouselItem,
} from "../ui/carousel";
import Autoplay from "embla-carousel-autoplay";
import companies from "../../data/companies.json";
import faqs from "../../data/faq.json";
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "../ui/Accordion";
import { Link } from "react-router-dom";
import { ScrollArea } from "../ui/scroll-area";

const LandingPage = ({type}) => {
  return (
    <main className="flex flex-col gap-10 sm:gap-20 py-10 sm:py-20">
      {/* <ScrollArea className="h-[calc(100vh-150px)] overflow-y-auto"> */}
      <section className="text-center ">
        <h1 className="flex flex-col items-center justify-center gradient-title font-extrabold text-4xl sm:text-6xl lg:text-6xl tracking-tighter py-4">
          Find Your Dream Job
          <span className="flex items-center gap-2 sm:gap-6">
            and get &nbsp;
            {/* <img
              src="/logo.png"
              className="h-9 sm:h-24 lg:h-32"
              alt="Hired"
            /> */}
            Hired
          </span>
        </h1>
        <p className="text-gray-300 sm:mt-4 text-xs sm:text-xl">
          Explore thousands of job listings or find the perfect candidate
        </p>
      </section>
      <div className="flex gap-6 justify-center">
      {type === "Candidate" ? (
    <Link to="/jobs">
      <Button 
      // variant="blue" 
      className=" bg-white text-black rounded-lg text-sm sm:text-base py-2 px-4 sm:px-6 lg:p-7 lg:w-44 "
      size="xl">
        Find Jobs
      </Button>
    </Link>
  ) : type === "Recruiter" ? (
    <Link to="/post-job">
      <Button 
      // variant="destructive"
      className=" bg-white text-black rounded-lg text-sm sm:text-base py-2 px-4 sm:px-6 lg:p-7 lg:w-44"
       size="xl">
        Post a Job
      </Button>
    </Link>

  ) : (
    <>
      <Link to="/jobs">
        <Button 
        // variant="blue" 
        size="xl" 
        // type="submit"
        className="bg-white text-black rounded-lg text-sm sm:text-base py-2 px-4 sm:px-6 lg:p-7 lg:w-44"
          // variant="outline"
          >
          Find Jobs
        </Button>
      </Link>
      <Link to="/post-job">
        <Button 
        // variant="destructive" 
        size="xl"
        className="bg-white text-black rounded-lg text-sm sm:text-base py-2 px-4 sm:px-6 lg:p-7 lg:w-44"
        >
          Post a Job
        </Button>
      </Link>
    </>
  )}
        
        
      </div>
      <Carousel
        plugins={[
          Autoplay({
            delay: 2000,
          }),
        ]}
        className="w-full py-10"
      >
        <CarouselContent className="flex gap-5 sm:gap-20 items-center">
          {companies.map(({ name, id, path }) => (
            <CarouselItem key={id} className="basis-1/3 lg:basis-1/6 ">
              <img
                src={path}
                alt={name}
                className="h-9 sm:h-14 object-contain"
              />
            </CarouselItem>
          ))}
        </CarouselContent>
      </Carousel>

      {/* <img src="/banner.jpeg" className="w-full" /> */}

      <section className="grid grid-cols-1 md:grid-cols-2 gap-4">
        <Card>
          <CardHeader>
            <CardTitle className="font-bold">For Job Seekers</CardTitle>
          </CardHeader>
          <CardContent>
            Search and apply for jobs, track applications, and more.
          </CardContent>
        </Card>
        <Card>
          <CardHeader>
            <CardTitle className="font-bold">For Employers</CardTitle>
          </CardHeader>
          <CardContent>
            Post jobs, manage applications, and find the best candidates.
          </CardContent>
        </Card>
      </section>

      <Accordion type="multiple" className="w-full">
        {faqs.map((faq, index) => (
          <AccordionItem key={index} value={`item-${index + 1}`}>
            <AccordionTrigger>{faq.question}</AccordionTrigger>
            <AccordionContent>{faq.answer}</AccordionContent>
          </AccordionItem>
        ))}
      </Accordion>
      {/* </ScrollArea> */}
    </main>
  );
};

export default LandingPage;