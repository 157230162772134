// "use client";

// import React from "react";
// import { Drawer as DrawerPrimitive } from "vaul";
// import { cn } from "../../lib/utils";

// const Drawer = ({ shouldScaleBackground = true, ...props }) => (
//   <DrawerPrimitive.Root
//     shouldScaleBackground={shouldScaleBackground}
//     {...props}
//   />
// );
// Drawer.displayName = "Drawer";

// const DrawerTrigger = DrawerPrimitive.Trigger;

// const DrawerPortal = DrawerPrimitive.Portal;

// const DrawerClose = DrawerPrimitive.Close;

// const DrawerOverlay = React.forwardRef(function DrawerOverlay(
//   { className, ...props },
//   ref
// ) {
//   return (
//     <DrawerPrimitive.Overlay
//       ref={ref}
//       className={cn("fixed inset-0 z-50 bg-black/80", className)}
//       {...props}
//     />
//   );
// });
// DrawerOverlay.displayName = DrawerPrimitive.Overlay.displayName;

// const DrawerContent = React.forwardRef(function DrawerContent(
//   { className, children, ...props },
//   ref
// ) {
//   return (
//     <DrawerPortal>
//     <DrawerOverlay />
//     <DrawerPrimitive.Content
//       ref={ref}
//       className={cn(
//         "fixed inset-x-0 bottom-0 z-50 mt-24 flex h-auto flex-col rounded-t-[10px] border bg-background max-w-[90%] sm:max-w-[500px] mx-auto", // Adjust width here
//         className
//       )}
//       {...props}>
//       <div className="mx-auto mt-4 h-2 w-[100px] rounded-full bg-muted" />
//       {children}
//     </DrawerPrimitive.Content>
//   </DrawerPortal>
//   );
// });
// DrawerContent.displayName = "DrawerContent";

// const DrawerHeader = ({ className, ...props }) => (
//   <div className={cn("grid gap-1.5 p-4 text-center sm:text-left", className)} {...props} />
// );
// DrawerHeader.displayName = "DrawerHeader";

// const DrawerFooter = ({ className, ...props }) => (
//   <div className={cn("mt-auto flex flex-col gap-2 p-4", className)} {...props} />
// );
// DrawerFooter.displayName = "DrawerFooter";

// const DrawerTitle = React.forwardRef(function DrawerTitle(
//   { className, ...props },
//   ref
// ) {
//   return (
//     <DrawerPrimitive.Title
//       ref={ref}
//       className={cn("text-lg font-semibold leading-none tracking-tight", className)}
//       {...props}
//     />
//   );
// });
// DrawerTitle.displayName = DrawerPrimitive.Title.displayName;

// const DrawerDescription = React.forwardRef(function DrawerDescription(
//   { className, ...props },
//   ref
// ) {
//   return (
//     <DrawerPrimitive.Description
//       ref={ref}
//       className={cn("text-sm text-muted-foreground", className)}
//       {...props}
//     />
//   );
// });
// DrawerDescription.displayName = DrawerPrimitive.Description.displayName;

// export {
//   Drawer,
//   DrawerPortal,
//   DrawerOverlay,
//   DrawerTrigger,
//   DrawerClose,
//   DrawerContent,
//   DrawerHeader,
//   DrawerFooter,
//   DrawerTitle,
//   DrawerDescription,
// };




"use client";

import React from "react";
import { Drawer as DrawerPrimitive } from "vaul";
import { cn } from "../../lib/utils";

const Drawer = ({ shouldScaleBackground = true, ...props }) => (
  <DrawerPrimitive.Root
    shouldScaleBackground={shouldScaleBackground}
    {...props}
  />
);
Drawer.displayName = "Drawer";

const DrawerTrigger = DrawerPrimitive.Trigger;

const DrawerPortal = DrawerPrimitive.Portal;

const DrawerClose = DrawerPrimitive.Close;

const DrawerOverlay = React.forwardRef(function DrawerOverlay(
  { className, ...props },
  ref
) {
  return (
    <DrawerPrimitive.Overlay
      ref={ref}
      className={cn("fixed inset-0 z-50 bg-black/80", className)}
      {...props}
    />
  );
});
DrawerOverlay.displayName = DrawerPrimitive.Overlay.displayName;

const DrawerContent = React.forwardRef(function DrawerContent(
  { className, children, ...props },
  ref
) {
  // Event handlers to stop closing the drawer during scroll/drag
  const handleWheel = (e) => {
    e.stopPropagation(); // Prevent scroll event from closing the drawer
  };

  const handleMouseDown = (e) => {
    e.stopPropagation(); // Prevent mouse down event from closing the drawer
  };

  const handleTouchMove = (e) => {
    e.stopPropagation(); // Prevent touch events from closing the drawer
  };

  return (
    <DrawerPortal>
      <DrawerOverlay />
      <DrawerPrimitive.Content
        ref={ref}
        className={cn(
          "fixed inset-x-0 bottom-0 z-50 mt-24 flex h-auto flex-col rounded-t-[10px] border bg-background max-w-[90%] sm:max-w-[500px] mx-auto",
          className
        )}
        {...props}
        onWheel={handleWheel}  // Prevent scroll event from closing the drawer
        onMouseDown={handleMouseDown}  // Prevent mouse events from closing the drawer
        onTouchMove={handleTouchMove}  // Prevent touch events from closing the drawer
      >
        <div className="mx-auto mt-4 h-2 w-[100px] rounded-full bg-muted" />
        {children}
      </DrawerPrimitive.Content>
    </DrawerPortal>
  );
});
DrawerContent.displayName = "DrawerContent";

const DrawerHeader = ({ className, ...props }) => (
  <div className={cn("grid gap-1.5 p-4 text-center sm:text-left", className)} {...props} />
);
DrawerHeader.displayName = "DrawerHeader";

const DrawerFooter = ({ className, ...props }) => (
  <div className={cn("mt-auto flex flex-col gap-2 p-4", className)} {...props} />
);
DrawerFooter.displayName = "DrawerFooter";

const DrawerTitle = React.forwardRef(function DrawerTitle(
  { className, ...props },
  ref
) {
  return (
    <DrawerPrimitive.Title
      ref={ref}
      className={cn("text-lg font-semibold leading-none tracking-tight", className)}
      {...props}
    />
  );
});
DrawerTitle.displayName = DrawerPrimitive.Title.displayName;

const DrawerDescription = React.forwardRef(function DrawerDescription(
  { className, ...props },
  ref
) {
  return (
    <DrawerPrimitive.Description
      ref={ref}
      className={cn("text-sm text-muted-foreground", className)}
      {...props}
    />
  );
});
DrawerDescription.displayName = DrawerPrimitive.Description.displayName;

export {
  Drawer,
  DrawerPortal,
  DrawerOverlay,
  DrawerTrigger,
  DrawerClose,
  DrawerContent,
  DrawerHeader,
  DrawerFooter,
  DrawerTitle,
  DrawerDescription,
};
